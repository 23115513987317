package de.connect2x.tammy

import androidx.compose.ui.graphics.Color

internal val accentColor = Color(0xff2bb819)

internal val md_theme_light_onPrimary = Color(0xFF040404)
internal val md_theme_light_primaryContainer = Color(0xff4ac840)
internal val md_theme_light_onPrimaryContainer = Color(0xFF212121)
internal val md_theme_light_secondary = Color(0xFFE0E8FF)
internal val md_theme_light_onSecondary = Color(0xFF101010)
internal val md_theme_light_secondaryContainer = Color(0xFF666666)
internal val md_theme_light_onSecondaryContainer = Color(0xFFE6E6E6)
internal val md_theme_light_tertiary = Color(0xFF333333)
internal val md_theme_light_onTertiary = Color(0xFFFFFFFF)
internal val md_theme_light_tertiaryContainer = Color(0xFF888888)
internal val md_theme_light_onTertiaryContainer = Color(0xFFFEFEFE)
internal val md_theme_light_error = Color(0xFF8A0D1D)
internal val md_theme_light_errorContainer = Color(0xFFFFDAD6)
internal val md_theme_light_onError = Color(0xFFFFFFFF)
internal val md_theme_light_onErrorContainer = Color(0xFF410002)
internal val md_theme_light_background = Color(0xFFFFFFFF)
internal val md_theme_light_onBackground = Color(0xFF000000)
internal val md_theme_light_surface = Color(0xFFFCFEFE)
internal val md_theme_light_onSurface = Color(0xFF121212)
internal val md_theme_light_surfaceVariant = Color(0xFFdcf1fc)
internal val md_theme_light_onSurfaceVariant = Color(0xFF222222)
internal val md_theme_light_outline = Color(0xFF002336)
internal val md_theme_light_inverseOnSurface = Color(0xFFD6F6FF)
internal val md_theme_light_inverseSurface = Color(0xFF00363F)
internal val md_theme_light_inversePrimary = Color(0xFF5BD5F9)   // FIXME
internal val md_theme_light_surfaceTint = Color(0xFF000000)      // FIXME
internal val md_theme_light_outlineVariant = Color(0xFFBFC8CC)   // FIXME
internal val md_theme_light_scrim = Color(0xFF000000)            // FIXME


// Dark theme template (mostly used for lightness/saturation)
internal val md_theme_dark_onPrimary = Color(0xFF040404)
internal val md_theme_dark_primaryContainer = Color(0xff005e05)
internal val md_theme_dark_onPrimaryContainer = Color(0xFFEEEEEE)
internal val md_theme_dark_secondary = Color(0xFF384144)
internal val md_theme_dark_onSecondary = Color(0xFFFFFFFF)
internal val md_theme_dark_secondaryContainer = Color(0xFF666666)
internal val md_theme_dark_onSecondaryContainer = Color(0xFFE6E6E6)
internal val md_theme_dark_tertiary = Color(0xFFE6E6E6)
internal val md_theme_dark_onTertiary = Color(0xFF101010)
internal val md_theme_dark_tertiaryContainer = Color(0xFF555555)
internal val md_theme_dark_onTertiaryContainer = Color(0xFFE6E6E6)
internal val md_theme_dark_error = Color(0xFF8A0D1D)
internal val md_theme_dark_errorContainer = Color(0xFFFFDAD6)
internal val md_theme_dark_onError = Color(0xFFFFFFFF)
internal val md_theme_dark_onErrorContainer = Color(0xFF410002)
internal val md_theme_dark_background = Color(0xFF121212)
internal val md_theme_dark_onBackground = Color(0xFFFFFFFF)
internal val md_theme_dark_surface = Color(0xFF040404)
internal val md_theme_dark_onSurface = Color(0xFFFFFFFF)
internal val md_theme_dark_surfaceVariant = Color(0xFF274d61)
internal val md_theme_dark_onSurfaceVariant = Color(0xFFFFFFFF)
internal val md_theme_dark_outline = Color(0xFF8ed5fa)
internal val md_theme_dark_inverseOnSurface = Color(0xFF161616)
internal val md_theme_dark_inverseSurface = Color(0xFFA6EEFF)    // FIXME
internal val md_theme_dark_inversePrimary = Color(0xFF00677E)    // FIXME
internal val md_theme_dark_surfaceTint = Color(0xFF5BD5F9)       // FIXME
internal val md_theme_dark_outlineVariant = Color(0xFF40484B)    // FIXME
internal val md_theme_dark_scrim = Color(0xFF101010)